<template>
  <div>
    <label for="descricaoProcesso">Descrição Processo</label>
    <b-form-textarea
      id="descricaoResp"
      v-model="processo.descricaoProb"
      placeholder="Descrição"
      rows="3"
    />

  </div>

</template>

<script>
import { BFormTextarea } from 'bootstrap-vue'

export default {
  components: { BFormTextarea },
  props: {
    processo: Object,
  },

}
</script>
<style lang="scss">
</style>
