<template>
  <div>
    <validation-observer ref="form">
      <cabecProcesso
        :processo="processo"
        :cliente="cliente"
        :contrato="contrato"
        :documentos-processo="documentosProcesso"
        :series-processo="seriesProcesso"
        :serie-selecionada="serieSelecionada"
        :tipo-processo="tipoProcesso"
        :tipo-processo-selecionado="tipoProcessoSelecionado"
        :prioridade-processo="prioridadeProcesso"
        :prioridade-selecionada="prioridadeSelecionada"
        :tecnico-processo="tecnicoProcesso"
        :tecnico-selecionado="tecnicoSelecionado"
        :estado-processo="estadoProcesso"
        :estado-selecionado="estadoSelecionado"
        :intervencoes-processo="intervencoesProcesso"
        :editar-processo="editarProcesso"
        @editar-processo-atualizado="atualizarEditarProcesso"
      />

      <b-card>

        <b-tabs pills>

          <!-- Tab: Account -->
          <b-tab active>
            <template #title>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Descricão</span>
            </template>
            <descricaoProcesso :processo="processo" />
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Intervenção</span>
            </template>
            <intervencoes
              :processo="processo"
              :tecnico-processo="tecnicoProcesso"
              :estado-processo="estadoProcesso"
              :tipo-intervencao="tipoIntervencao"
              :intervencoes-processo="intervencoesProcesso"
              :tipo-intervencao-selecionado="tipoIntervencaoSelecionado"
              :estado-interv-selecionado="estadoIntervSelecionado"
              :tecnico-interv-selecionado="tecnicoIntervSelecionado"
            />
          </b-tab>
          <b-tab v-if="processo.estado==='cb6803ea-7594-460e-9925-ee7de4e666b3'">
            <template #title>
              <feather-icon
                icon="DollarSignIcon"
                size="16"
                class="mr-0 mr-sm-50"
              />
              <span class="d-none d-sm-inline">Faturação</span>
            </template>
            <faturacao :processo="processo" />
          </b-tab>
          <b-tabs pills />
        </b-tabs>
        <b-row>
          <b-col>
            <b-button
              v-if="editarProcesso===false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="guardarProcesso()"
            >
              Guardar
            </b-button>
            <b-button
              v-if="editarProcesso===true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="alteraEditarProcesso()"
            >
              Editar
            </b-button>
            <b-button
              v-if="editarProcesso===true"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="enviaApenasEmail"
            >
              Enviar
            </b-button>
            <b-button
              v-if="editarProcesso===false"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              @click="abrirRelatorio"
            >
              Guardar e Enviar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>

        <b-modal
          ref="modal"
          hide-footer
          size="xl"
          @close="onClose"
        >
          <section class="invoice-preview-wrapper">

            <b-row
              v-if="processo"
              class="invoice-preview"
            >
              <b-col
                cols="12"
                xl="12"
                md="12"
              >
                <vue-html2pdf
                  ref="html2Pdf"
                  :show-layout="false"
                  :float-layout="false"
                  :enable-download="false"
                  :preview-modal="false"
                  :paginate-elements-by-height="1400"
                  :pdf-quality="2"
                  :pdf-format="'a4'"
                  :pdf-orientation="'portrait'"
                  :pdf-content-width="'100%'"
                  :manual-pagination="true"
                  :html-to-pdf-options="htmlToPdfOptions"
                  @beforeDownload="beforeDownload($event)"
                  @hasDownloaded="hasDownloaded($event)"
                >

                  <LayoutProcesso
                    slot="pdf-content"
                    :processo="processoEmail"
                    :intervencao="intervencaoEmail"
                    :lista-processo="listaProcesso"
                    :lista-intervencoes="listaIntervencoes"
                  />

                </vue-html2pdf>
              </b-col>
            </b-row>
          </section>
        </b-modal>

      </b-card>

    </validation-observer>
  </div>
</template>

<script>

import {
  BButton, BTab, BTabs, BCard, BCol, BRow, BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import VueHtml2pdf from 'vue-html2pdf'
import swal from 'sweetalert2'
import cabecProcesso from './Editar/CabecProcesso.vue'
import descricaoProcesso from './Editar/DescricaoProcesso.vue'
import faturacao from './Editar/Faturacao.vue'
import LayoutProcesso from './LayoutProcesso/LayoutProcesso.vue'
import intervencoes from './Intervencao/Adicionar.vue'

export default {

  components: {
    BButton,
    cabecProcesso,
    descricaoProcesso,
    BTab,
    BTabs,
    BCard,
    BCol,
    BRow,
    ValidationObserver,
    ValidationProvider,
    BModal,
    VueHtml2pdf,
    LayoutProcesso,
    intervencoes,
    faturacao,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      editarProcesso: false,
      gravacaoRealizada: false,
      intervencoesProcesso: {
        intervencao: [],

      },
      createIntervencoesProcesso: {
        intervencao: [],

      },
      updateIntervencoesProcesso: {
        intervencao: [],

      },
      tipoIntervencaoSelecionado: { tipoIntervencao: '', key: '' },
      estadoIntervSelecionado: { estado: '', key: '' },
      tecnicoIntervSelecionado: { tecnico: '', key: '' },
      idProcesso: '',
      userSigned: JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem('userSigned')))),
      processo: {
        id: '',
        tipoDoc: null,
        serie: null,
        numProcesso: 0,
        processo: null,
        cliente: null,
        tipoProcesso: null,
        motivo: 'b802de89-223e-4960-8bd8-3c469b800b1e',
        prioridade: null,
        estado: null,
        tecnico: null,
        descricaoProb: null,
        comoReproduzir: null,
        dataHoraAbertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19),
        dataHoraFimPrevista: null,
        fechado: 0,
        dataHoraFecho: null,
        utilizador: null,
        observacoes: null,
        contratoID: null,
        guiaIntervencao: null,
        faturado: 0,
        numFatura: null,
        valor: null,
      },
      cliente: {
        id: '',
        cliente: '',
        nome: '',
        nomeFiscal: '',
        morada: '',
        localidade: '',
        codigoPostal: '',
        nif: '',
        idPais: '',
        idMoeda: '',
        idZona: '',
        idVendedor: '',
        telefone: '',
        telemovel: '',
        email: '',
        emailServicos: '',
        ativo: '',
        bloqueado: '',
        observacoes: '',
      },
      seriesProcesso: { series: [] },
      serieSelecionada: { serie: '', key: '' },
      tipoProcesso: { tipoProcessos: [] },
      tipoProcessoSelecionado: { tipoProcesso: '', key: '' },
      prioridadeProcesso: { prioridades: [] },
      prioridadeSelecionada: { prioridade: '', key: '' },
      tecnicoProcesso: { tecnicos: [] },
      tecnicoSelecionado: { tecnico: '', key: '' },
      estadoProcesso: { estados: [] },
      estadoSelecionado: { estado: '', key: '' },

      tipoIntervencao: { tipoIntervencoes: [] },

      documentosProcesso: {
        codigo: '',
        descricao: '',
        inactivo: 0,
      },

      vendedor: {
        id: '',
        codigo: '',
        nome: '',
        email: '',
        telemovel: '',
      },
      moeda: {
        id: '',
        codigo: '',
        descricao: '',
      },

      contrato: {
        id: '',
        tipoDoc: '',
        serie: '',
        numero: 0,
        codigo: '',
        data: null,
        entidade: '',
        referencia: '',
        estado: '',
        cancelado: '',
        canceladoMotivo: '',
        periodoInicio: '',
        periodoFim: '',
        renovavel: '',
        moeda: '',
        valorContrato: 0,
        utilizador: '',
        vendedor: '',
        precoBase: 0,
        dataAbertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19),
        descricao: '',
        dataAdjudicacao: null,
        periodoValidade: '',
        horasValidade: '',
        horasTotais: '0.00',
        horasGastas: '0.00',
        incidenciasValidade: '',
        incidenciasTotais: 0,
        incidenciasGastas: 0,
        excPeriodoValidade: '',
        excPeriodoInicio: '',
        excPeriodoFim: '',
        excHorasValidade: '',
        excHorasTotais: '0.00',
        excHorasGastas: '0.00',
        excIncidenciasValidade: '',
        excIncidenciasTotais: 0,
        excIncidenciasGastas: 0,
      },

      idDownload: false,
      generatedPdf: null,
      htmlToPdfOptions: {
        margin: [0.5, 0, 1, 0],
        filename: '',
        enableLinks: false,
        enableDownload: false,
        pagebreak: { mode: ['css', 'legacy'] },
        html2canvas: {
          scale: 2, // higher quality
          windowWidth: 1400, // simulate a browser size that causes the page's responsive CSS to output a pleasing layout in the rendered PDF
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      },
      listaProcesso: [],
      listaIntervencoes: [],
      processoEmail: {},
      intervencaoEmail: [],
      isDownload: false,
    }
  },

  methods: {
    alteraEditarProcesso() {
      this.editarProcesso = false
    },
    atualizarEditarProcesso(novoValor) {
      this.editarProcesso = novoValor
    },
    onClose() {
      this.idProcesso = ''
      this.processo.id = ''
      this.processo.cliente = null
      this.processo.tipoProcesso = null
      this.processo.motivo = 'b802de89-223e-4960-8bd8-3c469b800b1e'
      this.processo.prioridade = null
      this.processo.estado = null
      this.processo.tecnico = null
      this.processo.descricaoProb = null
      this.processo.comoReproduzir = null
      this.processo.dataHoraAbertura = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19)
      this.processo.dataHoraFimPrevista = null
      this.processo.fechado = 0
      this.processo.dataHoraFecho = null
      this.processo.utilizador = null
      this.processo.observacoes = null
      this.processo.contratoID = null
      this.processo.guiaIntervencao = null
      this.processo.faturado = 0
      this.processo.numFatura = null
      this.processo.valor = null

      this.cliente = {
        id: '',
        cliente: '',
        nome: '',
        nomeFiscal: '',
        morada: '',
        localidade: '',
        codigoPostal: '',
        nif: '',
        idPais: '',
        idMoeda: '',
        idZona: '',
        idVendedor: '',
        telefone: '',
        telemovel: '',
        email: '',
        emailServicos: '',
        ativo: '',
        bloqueado: '',
        observacoes: '',
      }

      this.tipoProcessoSelecionado = { tipoProcesso: '', key: '' }

      this.prioridadeSelecionada = { prioridade: '', key: '' }

      this.tecnicoSelecionado = { tecnico: '', key: '' }

      this.estadoSelecionado = { estado: {}, key: '' }

      this.contrato = {
        id: '',
        tipoDoc: '',
        serie: '',
        numero: 0,
        codigo: '',
        data: null,
        entidade: '',
        referencia: '',
        estado: '',
        cancelado: '',
        canceladoMotivo: '',
        periodoInicio: '',
        periodoFim: '',
        renovavel: '',
        moeda: '',
        valorContrato: 0,
        utilizador: '',
        vendedor: '',
        precoBase: 0,
        dataAbertura: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 19),
        descricao: '',
        dataAdjudicacao: null,
        periodoValidade: '',
        horasValidade: '',
        horasTotais: '0.00',
        horasGastas: '0.00',
        incidenciasValidade: '',
        incidenciasTotais: 0,
        incidenciasGastas: 0,
        excPeriodoValidade: '',
        excPeriodoInicio: '',
        excPeriodoFim: '',
        excHorasValidade: '',
        excHorasTotais: '0.00',
        excHorasGastas: '0.00',
        excIncidenciasValidade: '',
        excIncidenciasTotais: 0,
        excIncidenciasGastas: 0,
      }

      this.idDownload = false
      this.generatedPdf = null
      this.htmlToPdfOptions = {
        margin: [0.5, 0, 1, 0],
        filename: '',
        enableLinks: false,
        enableDownload: false,
        pagebreak: { mode: ['css', 'legacy'] },
        html2canvas: {
          scale: 2, // higher quality
          windowWidth: 1400, // simulate a browser size that causes the page's responsive CSS to output a pleasing layout in the rendered PDF
        },

        jsPDF: {
          unit: 'in',
          format: 'a4',
          orientation: 'portrait',
        },
      }

      this.listaProcesso = []
      this.listaIntervencoes = []
      this.processoEmail = {}
      this.isDownload = false
      this.alterarNumero()
    },

    async abrirRelatorio() {
      try {
        await this.guardarProcesso()
        await this.getProcesso(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.getIntervencoes(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.showModalClientes()
        await this.getProcesso(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.getIntervencoes(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.criaPDFAbertura()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async enviaApenasEmail() {
      try {
        await this.getProcesso(this.processo.tipoDoc, this.processo.serie, this.processo.numProcesso)
        await this.getIntervencoes(this.processo.tipoDoc, this.processo.serie, this.processo.numProcesso)
        await this.showModalClientes()
        await this.getProcesso(this.processo.tipoDoc, this.processo.serie, this.processo.numProcesso)
        await this.getIntervencoes(this.processo.tipoDoc, this.processo.serie, this.processo.numProcesso)
        await this.criaPDFAbertura()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async guardar() {
      try {
        await this.guardarProcesso()
        await this.getProcesso(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.getIntervencoes(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.getProcesso(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
        await this.getIntervencoes(this.processoEmail.tipoDoc, this.processoEmail.serie, this.processoEmail.numProcesso)
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf().set(options).from(pdfContent).toPdf()
        .get('pdf')
        .then(pdf => {
          const totalPages = pdf.internal.getNumberOfPages()
          for (let i = 1; i <= totalPages; i += 1) {
            pdf.setPage(i)
            pdf.setFontSize(10)
            pdf.setTextColor(150)
            pdf.text(`Pág. ${i} de ${totalPages}`, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
          }
          this.generatedPdf = pdf.output('dataurlstring')
        })
        .get()
      await this.hasDownloaded()
    },

    async hasDownloaded() {
      await this.enviaEmail()
    },

    async criaPDFAbertura() {
      try {
        this.isDownload = false
        await this.$refs.html2Pdf.generatePdf()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async enviaEmail() {
      try {
        const response = await axios.post(`/api/v1/processos/${this.processoEmail.id}/enviaRelatorio`, {
          pdf: this.generatedPdf.split('base64,')[1],
          nomePdf: this.nomeFicheiro,
        })
        this.autoClose()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    autoClose() {
      swal.fire({
        title: 'Email enviado com sucesso !',
        timer: 3000,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },

    async getProcesso(tipoDoc, serie, numProcesso) {
      try {
        this.listaProcesso = []
        const response = await axios.get(`/api/v1/processos/tipodoc/${tipoDoc}/serie/${serie}/numdoc/${numProcesso}/complete`)
        this.processoEmail = response.data
        this.listaProcesso.push(response.data)
        this.nomeFicheiro = response.data.processo
        this.htmlToPdfOptions.filename = response.data.processo
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
      if (this.processo.faturado === 1) {
        this.editarProcesso = true
      }
    },

    async getIntervencoes(tipoDoc, serie, numProcesso) {
      try {
        this.listaIntervencoes = []
        const response = await axios.get(`/api/v1/intervencoes/tipodoc/${tipoDoc}/serie/${serie}/numdoc/${numProcesso}/complete`)
        this.intervencao = response.data
        this.listaIntervencoes.push(response.data)
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },

    async showModalClientes() {
      try {
        await this.$refs.modal.show()
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },
    hideModalClientes() {
      this.$refs.modal.hide()
    },

    guardarEEnviarEmail() {
      this.showModalClientes()
    },
    async switchLoc() {
      try {
        await this.$refs.form.validate()
        const valid = await this.$refs.form.valid
        if (!valid) {
          this.erroGravacao('danger', 'CheckIcon')

          // Trate o caso de validação inválida, se necessário.
        } else {
          await this.guardarProcesso()
        }
      } catch (error) {
        // Trate o erro aqui ou lance-o novamente para cima, se necessário.
      }
    },
    async alterarNumero() {
      await axios.get(`/api/v1/seriesProcesso/${this.processo.serie}/ultimoNumero`)
        .then(res => {
          this.processo.numProcesso = res.data.numerador + 1
        })
      this.$forceUpdate()
    },
    async atualizaIDProcesso() {
      await axios.get(`/api/v1/processos/tipodoc/${this.processo.tipoDoc}/serie/${this.processo.serie}/numdoc/${this.processo.numProcesso}/complete`)
        .then(res => {
          this.processo.id = res.data.id
        })
    },
    async guardarIntervencao() {
      await this.atualizaIDProcesso()
      for (let i = 0; i < this.intervencoesProcesso.intervencao.length; i += 1) {
        if (this.intervencoesProcesso.intervencao[i].id === '') {
          this.intervencoesProcesso.intervencao[i].processoID = this.processo.id

          this.intervencoesProcesso.intervencao[i].estadoAnt = 'd0531a28-ed3a-489b-925d-fa325dc6be6d'
          this.intervencoesProcesso.intervencao[i].moeda = 'c38e279e-43a3-441a-ad00-8118ed2d4895'
          this.intervencoesProcesso.intervencao[i].utilizador = this.userSigned.id
          this.intervencoesProcesso.intervencao[i].imputadoContrato = 0
          this.intervencoesProcesso.intervencao[i].facturado = 0
          if (this.intervencoesProcesso.intervencao[i].tempoEfetivo === false) {
            this.intervencoesProcesso.intervencao[i].duracaoTempoEfetivo = 0
          }
          this.intervencoesProcesso.intervencao[i].descricaoResp = encodeURIComponent(this.intervencoesProcesso.intervencao[i].descricaoResp)
          const resultado = new Promise((resolve, reject) => {
            axios
              .post('/api/v1/intervencoes', this.intervencoesProcesso.intervencao[i])
              .then(response => {
                resolve(response, this.intervencoesProcesso.intervencao[i].id = response.data.id)
              })
              .catch(error => reject(error))
          })
        } else {
          if (this.intervencoesProcesso.intervencao[i].tempoEfetivo === false) {
            this.intervencoesProcesso.intervencao[i].duracaoTempoEfetivo = 0
          }
          this.intervencoesProcesso.intervencao[i].descricaoResp = encodeURIComponent(this.intervencoesProcesso.intervencao[i].descricaoResp)
          const resultado = new Promise((resolve, reject) => {
            axios.put(`/api/v1/intervencoes/${this.intervencoesProcesso.intervencao[i].id}`, this.intervencoesProcesso.intervencao[i])
              .then(response => {
                resolve(response)
              })
              .catch(error => reject(error))
          })
        }
        this.intervencoesProcesso.intervencao[i].descricaoResp = decodeURIComponent(this.intervencoesProcesso.intervencao[i].descricaoResp)
      }
    },
    async guardarProcesso() {
      this.processo.utilizador = this.userSigned.id
      this.processo.processo = `${this.documentosProcesso.codigo}/${this.serieSelecionada.serie.serie}/${this.processo.numProcesso}`
      this.processoEmail.tipoDoc = this.processo.tipoDoc
      this.processoEmail.serie = this.processo.serie
      this.processoEmail.numProcesso = this.processo.numProcesso
      if (this.processo.faturado === 0 || this.processo.estado !== 'cb6803ea-7594-460e-9925-ee7de4e666b3') {
        this.processo.faturado = 0
        this.processo.valor = 0
        this.processo.numFatura = ''
      }

      if (this.processo.id === '') {
        this.processo.descricaoProb = encodeURIComponent(this.processo.descricaoProb)
        await this.alterarNumero()
        await new Promise((resolve, reject) => {
          axios
            .post('/api/v1/processos', this.processo)
            .then(response => {
              resolve(response)
              for (let i = 0; i < this.intervencoesProcesso.length; i += 1) {
                this.intervencao.utilizador = this.userSigned.id
                this.processo.processo = `${this.documentosProcesso.codigo}/${this.serieSelecionada.serie.serie}/${this.processo.numProcesso}`
                this.processoEmail.tipoDoc = this.processo.tipoDoc
                this.processoEmail.serie = this.processo.serie
                this.processoEmail.numProcesso = this.processo.numProcesso
              }
              this.guardarIntervencao()
              this.gravacaoRealizada = true
            })
            .catch(error => reject(error))
        })
      } else {
        // this.processo.descricaoProb = encodeURIComponent(this.processo.descricaoProb)
        this.processo.descricaoProb = encodeURIComponent(this.processo.descricaoProb)

        await new Promise((resolve, reject) => {
          axios
            .put(`/api/v1/processos/${this.processo.id}`, this.processo)
            .then(response => resolve(response, this.guardarIntervencao()))
            .catch(error => reject(error))
        })
      }
      this.processo.descricaoProb = decodeURIComponent(this.processo.descricaoProb)
      this.$forceUpdate()
    },

    showToast(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Registo gravado com sucesso !',
          variant,
        },

      })
    },

    emailEnviadoComSucesso(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Sucesso',
          icon,
          text: 'Email Enviado com sucesso !',
          variant,
        },

      })
    },
    erroGravacao(variant, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Erro',
          icon,
          text: 'O Registo apresenta erros !',
          variant,
        },

      })
    },
  },
}
</script>

<style></style>
