<template>
  <div>
    <b-row>
      <b-col
        md="2"
        lg="2"
      >
        <b-form-group
          label="Faturado"
          label-for="faturado"
          label-class="mb-1"
          inline
        >
          <b-form-checkbox
            id="faturado"
            v-model="processo.faturado"
            :value="1"
            :unchecked-value="0"
            switch
            inline
          />

        </b-form-group>
      </b-col>
      <b-col
        v-if="processo.faturado===1"
        md="4"
      >
        <b-form-group
          label="Fatura"
          label-for="h-numFatura"
        >
          <b-form-input
            id="h-numFatura"
            v-model="processo.numFatura"
            placeholder="Fatura"
            maxlength="50"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="processo.faturado===1"
        md="3"
      >
        <b-form-group
          label="Valor"
          label-for="h-valor"
        >
          <b-form-input
            v-model="processo.valor"
            type="number"
            class="mb-2"
            placeholder="0"
          />
        </b-form-group>
      </b-col>

    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BFormGroup, BFormInput, BFormCheckbox,
  },
  props: {
    processo: Object,
  },

}
</script>
  <style lang="scss">
  </style>
