<template>
  <div>
    <div>

      <div>
        <br>

        <b-form
          ref="form"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >

          <!-- Row Loop -->
          <b-row
            v-for="(intervencao, index) in intervencoesProcesso.intervencao"
            :key="index"
            ref="row"
            class="pb-2"
          >
            <b-col md="2">
              <b-form-group
                label="Intervenção"
                label-for="h-interv"
              >
                <div>
                  <h4>
                    <span> {{ intervencao.interv }} </span>

                  </h4>
                </div>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Tipo Intervenção"
                label-for="h-tipoIntervencao"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tipo Intervenção"
                  rules="required"
                >
                  <v-select
                    v-model="intervencao.tipoInterv"
                    :options="tipoIntervencao.tipoIntervencoes"
                    label="descricao"
                    title="descricao"
                    :reduce="tipoIntervencaoSelecionado => tipoIntervencaoSelecionado.id"
                    required
                  >
                    <template #tipoIntervencao="tipoIntervencao">
                      <span>{{ tipoIntervencao.codigo }} - {{ tipoIntervencao.descricao }}</span>
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </v-select></validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group
                label="Técnico"
                label-for="h-tecnico"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Técnico"
                  rules="required"
                >
                  <v-select
                    v-model="intervencao.tecnico"
                    :options="tecnicoProcesso.tecnicos"
                    label="nome"
                    title="nome"
                    :reduce="tecnicoIntervSelecionado => tecnicoIntervSelecionado.id"
                    required
                  >
                    <template #tecnico="tecnico">
                      <span>{{ tecnico.codigo }} - {{ tecnico.nome }}</span>
                    </template>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </v-select></validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label="Estado"
                label-for="h-estado"
              >
                <v-select
                  v-model="intervencao.estado"
                  :options="estadoProcesso.estados"
                  label="descricao"
                  title="descricao"
                  :reduce="estadoIntervSelecionado => estadoIntervSelecionado.id"
                  required
                >
                  <template #estado="estado">
                    <span>{{ estado.codigo }} - {{ estado.descricao }}</span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              lg="2"
            >
              <b-form-group
                label="Data Inicio"
                label-for="dataHoraInicio"
              >
                <flat-pickr
                  v-model="intervencao.dataHoraInicio"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              lg="2"
            >
              <b-form-group
                label="Data Fim"
                label-for="dataHoraFim"
              >
                <flat-pickr
                  v-model="intervencao.dataHoraFim"
                  class="form-control"
                  :config="config"
                  placeholder="YYYY-MM-DD"
                />
              </b-form-group>
            </b-col>
            <b-col
              md="2"
              lg="2"
            >
              <b-form-group
                label="Tempo Efetivo"
                label-for="tempoEfetivo"
                label-class="mb-1"
              >
                <b-form-checkbox
                  :id="'tempoEfetivo' + index"
                  v-model="intervencao.tempoEfetivo"
                  :value="true"
                />

              </b-form-group>
            </b-col>
            <b-col
              v-if="intervencao.tempoEfetivo===true"
              md="2"
              lg="2"
            >
              <b-form-group
                label="Duração em minutos"
                label-for="duracaoTempoEfetivo"
              >
                <b-form-input
                  id="duracaoTempoEfetivo"
                  v-model="intervencao.duracaoTempoEfetivo"
                  maxlength="50"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Duracao Real"
                label-for="h-duracaoReal"
              >
                <div>
                  <h4>
                    <span> {{ intervencao.duracaoReal }} </span>

                  </h4>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group
                label="Duracao"
                label-for="h-duracao"
              >
                <div>
                  <h4>
                    <span> {{ intervencao.duracao }} </span>

                  </h4>
                </div>
              </b-form-group>
            </b-col>
            <b-col
              md="12"
              lg="12"
            >
              <b-form-group
                label="Descrição"
                label-for="descricaoResp"
              >
                <b-form-textarea
                  id="descricaoResp"
                  v-model="intervencao.descricaoResp"
                  placeholder="Descrição"
                  rows="3"
                />
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col
              md="2"
              lg="2"
              class="mb-50"
            >
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-25"
                />
                <span>Apagar</span>
              </b-button>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="adicionarNovaintervencao"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Nova Intervenção</span>
          </b-button>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormCheckbox, BFormTextarea,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import axios from 'axios'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    Cleave,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    vSelect,
    flatPickr,

  },
  directives: {
    Ripple,
  },
  props: {
    processo: Object,
    tecnicoProcesso: Object,
    estadoProcesso: Object,
    tipoIntervencao: Object,
    intervencoesProcesso: Object,
    tipoIntervencaoSelecionado: Object,
    estadoIntervSelecionado: Object,
    tecnicoIntervSelecionado: Object,
  },
  data() {
    return {

      config: {
        enableTime: true,
        altFormat: 'd-m-Y H:i',
        altInput: true,
        dateFormat: 'Y-m-dTH:i:00',
        locale: Portuguese, // locale for this instance only
      },
      required,
      configNumerador: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralIntegerScale: 6,
        },
      },
      configOrdem: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralIntegerScale: 2,
        },
      },
      i: 0,

    }
  },
  setup() {
    const intervencaoEmBranco = {
      id: '',
      processoID: '',
      interv: '',
      tipoInterv: '',
      descricaoResp: '',
      duracao: '',
      duracaoReal: '',
      dataHoraInicio: '',
      dataHoraFim: '',
      tecnico: '',
      estadoAnt: '',
      estado: '',
      moeda: '',
      utilizador: '',
      imputadoContrato: '',
      facturado: '',
      tempoEfetivo: false,
      duracaoTempoEfetivo: 0,
    }

    const intervencao = ref({
      items: [JSON.parse(JSON.stringify(intervencaoEmBranco))],
    })

    return {
      intervencao,
      intervencaoEmBranco,
    }
  },
  watch: {
    'intervencoesProcesso.intervencao': {
      deep: true,
      handler: 'calcularDuracaoReal',
    },
  },
  created() {
    this.getTipoIntervencao()

    localize('pt', pt)
  },
  methods: {

    // created
    getTipoIntervencao() {
      this.tipoIntervencao.tipoIntervencoes = []
      axios.get('/api/v1/tiposIntervencao')
        .then(res => {
          const resultado = res.data
          this.tipoIntervencao.tipoIntervencoes.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.tipoIntervencao.tipoIntervencoes.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },
    // fim created

    calcularDuracaoReal() {
      for (let i = 0; i < this.intervencoesProcesso.intervencao.length; i += 1) {
        const intervencao = this.intervencoesProcesso.intervencao[i]

        if (intervencao.dataHoraInicio && intervencao.dataHoraFim) {
          const inicio = new Date(intervencao.dataHoraInicio)
          const fim = new Date(intervencao.dataHoraFim)

          let duracaoEmMinutos

          if (intervencao.tempoEfetivo === true) {
            duracaoEmMinutos = intervencao.duracaoTempoEfetivo // Usar o valor de duracaoTempoEfetivo efetivo
          } else {
            const diferencaEmMilissegundos = fim - inicio
            duracaoEmMinutos = diferencaEmMilissegundos / (1000 * 60) // Usar a diferença entre datas
          }

          const tipoIntervencao = this.tipoIntervencao.tipoIntervencoes.find(tipo => tipo.id === intervencao.tipoInterv)
          const duracaoMinima = tipoIntervencao.tempoDebMin

          // Tratar o caso de duração zero
          if (duracaoEmMinutos === 0) {
            if (intervencao.tempoEfetivo === true) {
              intervencao.duracao = 0
            } else {
              intervencao.duracao = duracaoMinima
            }
          } else {
            // Calcular a duração em blocos de tempoDebMin
            intervencao.duracao = Math.ceil(duracaoEmMinutos / duracaoMinima) * duracaoMinima
          }

          // Atualizar duracaoReal
          intervencao.duracaoReal = duracaoEmMinutos
        }
      }
    },

    adicionarNovaintervencao(index) {
      this.updateIndex(index)
      this.$refs.form.style.overflow = 'hidden'

      // Encontre o último valor de "interv"
      const lastInterv = this.intervencoesProcesso.intervencao.reduce(
        (maxInterv, intervObj) => Math.max(maxInterv, intervObj.interv), 0,
      )

      // Defina o próximo valor de "interv" para a próxima intervenção
      this.intervencoesProcesso.intervencao.push({
        ...JSON.parse(JSON.stringify(this.intervencaoEmBranco)),
        interv: lastInterv + 1,
      })
    },

    removeItem(index) {
      this.intervencoesProcesso.intervencao.splice(index, 1)
    },

    updateIndex(index) {
      this.i = index
    },

  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';

    @import '@core/scss/vue/libs/vue-good-table.scss';
    .repeater-form {
      overflow: hidden;
      transition: .35s height;
    }
    </style>
      <style lang="scss">
      @import '@core/scss/vue/libs/vue-select.scss';
      @import '@core/scss/vue/libs/vue-flatpicker.scss';
      .textoCentrado {
        text-align: center;
      }
      </style>
