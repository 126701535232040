<template>
  <b-card>
    <b-form
      @submit.prevent
    >
      <validation-observer ref="form">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Referência"
              label-for="h-referencia"
            >
              <div>
                <h4>
                  <span v-if="documentosProcesso.codigo !='' && processo.numProcesso != '' && processo.serie != ''">{{ documentosProcesso.codigo }}/{{ serieSelecionada.serie.serie }}/{{ processo.numProcesso }}</span>
                  <span v-else>#</span>
                </h4>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            v-if="processo.estado==='cb6803ea-7594-460e-9925-ee7de4e666b3'"
            md="6"
          >
            <b-form-group
              label="Faturado"
              label-for="h-fatura"
            >
              <div>
                <h4>
                  <span style="color: red;">Fatura: {{ processo.numFatura }}</span>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="4"
            lg="4"
          >
            <b-form-group
              label="Documento"
              label-for="tipoDoc"
            >
              <validation-provider
                #default="{ errors }"
                name="Documento"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="tipoDoc"
                    v-model="documentosProcesso.descricao"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalDocumentosProcesso"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Série"
              label-for="h-serie"
            >
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <v-select
                  v-model="processo.serie"
                  :options="seriesProcesso.series"
                  label="serie"
                  title="serie"
                  :reduce="serieSelecionada => serieSelecionada.id"
                  required
                  @input="alterarNumero(processo.serie)"
                >
                  <template #option="option">
                    <span>{{ option.serie }} - {{ option.descricao }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group
              label="Número"
              label-for="h-numero"
              class="align-text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Número"
                rules="required"
              >
                <b-input-group>
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="subtraiNumerador(processo.numProcesso), getProcesso()"
                    >
                      <feather-icon icon="ChevronLeftIcon" />
                    </b-button>
                  </b-input-group-prepend>
                  <b-form-input
                    id="numero"
                    v-model="processo.numProcesso"
                    class="textoCentrado"
                    type="number"
                    maxlength="15"
                    @blur="getProcesso()"
                  />
                  <b-input-group-prepend>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      class="btn-icon"
                      variant="outline-secondary"
                      @click="somaNumerador(processo.numProcesso), getProcesso()"
                    >

                      <feather-icon icon="ChevronRightIcon" />
                    </b-button>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalProcessos"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
          <b-col
            md="3"
            lg="3"
          >
            <b-form-group
              label="Data Abertura"
              label-for="dataHoraAbertura"
            >
              <flat-pickr
                v-model="processo.dataHoraAbertura"
                class="form-control"
                :config="config"
                placeholder="YYYY-MM-DD"
              />
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            md="2"
            lg="2"
          >
            <b-form-group
              label="Entidade"
              label-for="tipoDoc"
            >
              <validation-provider
                #default="{ errors }"
                name="Entidade"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="entidade"
                    v-model="cliente.cliente"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalClientes"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Nome"
              label-for="h-nome"
            >
              <div>
                <h5>
                  <span v-if="cliente.nome != ''">{{ cliente.nome }} | {{ cliente.telefone }} - {{ cliente.telemovel }}</span>
                  <span v-else>Selecionar Cliente</span>

                </h5>
              </div>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col
            md="4"
            lg="4"
          >
            <b-form-group
              label="Contrato"
              label-for="contrato"
            >
              <validation-provider
                #default="{ errors }"
                name="Contrato"
                rules="required"
              >
                <b-input-group>
                  <b-form-input
                    id="contrato"
                    v-model="contrato.referencia"
                    type="text"
                    maxlength="15"
                  />
                  <b-input-group-prepend>
                    <b-button
                      variant="outline-secondary"
                      @click="showModalContratos"
                    >
                      <feather-icon icon="SearchIcon" />
                    </b-button>
                  </b-input-group-prepend>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Horas Totais"
              label-for="h-horasTotais"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="contrato.referencia != ''">{{ contrato.horasTotais }}</span>
                    <span v-else>0</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Horas Gastas"
              label-for="h-horasGastas"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="contrato.referencia != ''">{{ contrato.horasGastas }}</span>
                    <span v-else>0</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Saldo"
              label-for="h-horasDisponiveis"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="contrato.referencia != ''">{{ contrato.horasDisponiveis }}</span>
                    <span v-else>0</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group
              label="Estado"
              label-for="h-estado"
            >
              <div>
                <h4>
                  <h4>
                    <span v-if="contrato.referencia != ''">{{ contrato.descricaoEstado }}</span>
                    <span v-else>0</span>

                  </h4>

                </h4>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col md="3">
            <b-form-group
              label="Tipo Processo"
              label-for="h-tipoProcesso"
            >
              <validation-provider
                #default="{ errors }"
                name="Tipo Processo"
                rules="required"
              >
                <v-select
                  v-model="processo.tipoProcesso"
                  :options="tipoProcesso.tipoProcessos"
                  label="descricao"
                  title="descricao"
                  :reduce="tipoProcessoSelecionado => tipoProcessoSelecionado.id"
                  required
                >
                  <template #tipoProcesso="tipoProcesso">
                    <span>{{ tipoProcesso.codigo }} - {{ tipoProcesso.descricao }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Prioridade"
              label-for="h-prioridade"
            >
              <validation-provider
                #default="{ errors }"
                name="Prioridade"
                rules="required"
              >
                <v-select
                  v-model="processo.prioridade"
                  :options="prioridadeProcesso.prioridades"
                  label="descricao"
                  title="descricao"
                  :reduce="prioridadeSelecionada => prioridadeSelecionada.id"
                  required
                >
                  <template #prioridade="prioridade">
                    <span>{{ prioridade.codigo }} - {{ prioridade.descricao }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Técnico"
              label-for="h-tecnico"
            >
              <validation-provider
                #default="{ errors }"
                name="Técnico"
                rules="required"
              >
                <v-select
                  v-model="processo.tecnico"
                  :options="tecnicoProcesso.tecnicos"
                  label="nome"
                  title="nome"
                  :reduce="tecnicoSelecionado => tecnicoSelecionado.id"
                  required
                >
                  <template #tecnico="tecnico">
                    <span>{{ tecnico.codigo }} - {{ tecnico.nome }}</span>
                  </template>
                  <small class="text-danger">{{ errors[0] }}</small>
                </v-select></validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Estado"
              label-for="h-estado"
            >
              <v-select
                v-model="processo.estado"
                :options="estadoProcesso.estados"
                label="descricao"
                title="descricao"
                :reduce="estadoSelecionado => estadoSelecionado.id"
                required
              >
                <template #estado="estado">
                  <span>{{ estado.codigo }} - {{ estado.descricao }}</span>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </validation-observer>
    </b-form>
    <b-modal
      ref="modalContratos"
      hide-footer
      size="xl"
    >
      <selecionarContratoCliente
        :processo="processo"
        @contratoSelecionado="selectContratos"
      />
    </b-modal>
    <b-modal
      ref="modalDocumentosProcesso"
      hide-footer
      size="xl"
    >
      <selecionarDocumentoProcesso @documentosProcessoSelecionado="selectDocumentosProcesso" />
    </b-modal>
    <b-modal
      ref="modalClientes"
      hide-footer
      size="xl"
    >
      <selecionarCliente @clienteSelecionado="selectCliente" />
    </b-modal>
    <b-modal
      ref="modalProcessos"
      hide-footer
      size="xl"
    >
      <selecionarProcesso @processoSelecionado="selectProcesso" />
    </b-modal>

  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroupPrepend,
  BInputGroup, BModal,

} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import pt from 'vee-validate/dist/locale/pt_PT'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import router from '@/router'
import selecionarProcesso from '../../Listagens/Processos/SelecionarProcesso.vue'
import selecionarCliente from '../../Listagens/Clientes/SelecionarCliente.vue'
import selecionarDocumentoProcesso from '../../Listagens/DocumentosProcessos/SelecionarDocumentoProcessos.vue'
import selecionarContratoCliente from '../../Listagens/Contratos/SelecionarContratoCliente.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    BInputGroup,
    BModal,
    vSelect,
    flatPickr,
    selecionarProcesso,
    selecionarCliente,
    selecionarDocumentoProcesso,
    selecionarContratoCliente,
  },
  directives: {
    Ripple,
  },
  props: {
    cliente: Object,
    processo: Object,
    contrato: Object,
    documentosProcesso: Object,
    seriesProcesso: Object,
    serieSelecionada: Object,
    tipoProcesso: Object,
    tipoProcessoSelecionado: Object,
    prioridadeProcesso: Object,
    prioridadeSelecionada: Object,
    tecnicoProcesso: Object,
    tecnicoSelecionado: Object,
    estadoProcesso: Object,
    estadoSelecionado: Object,
    intervencoesProcesso: Object,
    gravacaoRealizada: Boolean,
    editarProcesso: Boolean,

  },

  data() {
    return {
      editarProcessoLocal: this.editarProcesso,
      items: [], // Dados da tabela
      fields: [
        {
          key: 'processo', label: 'Processo',
        },
        {
          key: 'dataHoraAbertura', label: 'Data',
        },
        { key: 'codigoCliente', label: 'Cliente', sortable: true },
        { key: 'nomeCliente', label: 'Nome', sortable: true },
        { key: 'referenciaContrato', label: 'Contrato', sortable: true },
        { key: 'descricaoProb', label: 'Problema', sortable: true },
        { key: 'descricaoEstado', label: 'Estado', sortable: true },
        { key: 'actions' },
      ],
      currentPage: 1,
      perPage: 15,
      pageOptions: [5, 10, 15, 50],
      searchQuery: null,
      opcoesEstado: [
        'PENDENTE',
        'REVISTO',
        'FATURADO',
        'TERMINADO',
        'INICIADO',
      ],
      filtroEstado: null,
      status: [{
        PENDENTE: 'PENDENTE', REVISTO: 'REVISTO', FATURADO: 'FATURADO', TERMINADO: 'TERMINADO', INICIADO: 'INICIADO', CANCELADO: 'CANCELADO',
      },
      {
        PENDENTE: 'light-primary', INICIADO: 'light-success', CANCELADO: 'light-danger', FATURADO: 'light-warning', REVISTO: 'light-info', TERMINADO: 'light-dark',
      }],
      dataMeta: {},

      config: {
        enableTime: true,
        altFormat: 'd-m-Y H:i',
        altInput: true,
        dateFormat: 'Y-m-dTH:i:00',
        locale: Portuguese, // locale for this instance only
      },
      required,

    }
  },
  computed: {

    totalRows() {
      return this.items.length
    },
    pagedItems() {
      const start = (this.currentPage - 1) * this.perPage
      const end = start + this.perPage
      return this.items.slice(start, end)
    },

    filteredItems() {
      // Filtrar com base no estado selecionado
      const stateFiltered = this.items.filter(item => this.filtroEstado === null || this.filtroEstado === item.descricaoEstado)

      // Filtrar com base na busca
      return stateFiltered.filter(item => {
        if (!this.searchQuery) {
          return true // Mostrar todos se a busca estiver vazia
        }
        const query = this.searchQuery.toLowerCase()
        return (
          item.processo.toLowerCase().includes(query)
      || item.codigoCliente.toLowerCase().includes(query)
      || item.nomeCliente.toLowerCase().includes(query)
      || item.referenciaContrato.toLowerCase().includes(query)
      || item.descricaoProb.toLowerCase().includes(query)
        )
      })
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getTipoProcesso()
    this.getPrioridades()
    this.getTecnicos()
    this.getEstados()
    this.getProcessos()
    if (router.currentRoute.params.id !== undefined) {
      this.getOneProcesso()
    }
    localize('pt', pt)
  },

  methods: {
    // created
    getProcessos() {
      axios.get('/api/v1/processos/complete')
        .then(res => {
          this.items = res.data
        })
    },
    getTipoProcesso() {
      this.tipoProcessoSelecionado.tipoProcesso = []
      this.tipoProcesso.tipoProcessos = []
      axios.get('/api/v1/tiposProcesso/complete')
        .then(res => {
          const resultado = res.data
          this.tipoProcesso.tipoProcessos.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.tipoProcesso.tipoProcessos.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },
    getEstados() {
      this.estadoSelecionado.estado = []
      this.estadoProcesso.estados = []
      axios.get('/api/v1/estados/processos')
        .then(res => {
          const resultado = res.data
          this.estadoProcesso.estados.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.estadoProcesso.estados.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },
    getPrioridades() {
      this.prioridadeSelecionada.prioridade = []
      this.prioridadeProcesso.prioridades = []
      axios.get('/api/v1/prioridades')
        .then(res => {
          const resultado = res.data
          this.prioridadeProcesso.prioridades.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.prioridadeProcesso.prioridades.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },

    getTecnicos() {
      this.tecnicoSelecionado.tecnico = []
      this.tecnicoProcesso.tecnicos = []
      axios.get('/api/v1/tecnicos')
        .then(res => {
          const resultado = res.data
          this.tecnicoProcesso.tecnicos.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.tecnicoProcesso.tecnicos.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.$forceUpdate()
    },
    // fim created

    getIntervencoes() {
      this.intervencoesProcesso.intervencao = []

      axios.get(`/api/v1/intervencoes/processo/${this.processo.id}/complete`)
        .then(res => {
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i += 1) {
              let tEfetivo = null
              if (res.data[i].tempoEfetivo === 1) {
                tEfetivo = true
              } else {
                tEfetivo = false
              }
              const intervencao = {
                id: res.data[i].id,
                processoID: res.data[i].processoID,
                interv: res.data[i].interv,
                tipoInterv: res.data[i].tipoInterv,
                descricaoResp: res.data[i].descricaoResp,
                duracao: res.data[i].duracao,
                duracaoReal: res.data[i].duracaoReal,
                dataHoraInicio: res.data[i].dataHoraInicio, // Converter para o formato 'AAAA-MM-DD HH:MM:SS'
                dataHoraFim: res.data[i].dataHoraFim,
                tecnico: res.data[i].tecnico,
                estadoAnt: res.data[i].estadoAnt,
                estado: res.data[i].estado,
                moeda: res.data[i].moeda,
                utilizador: res.data[i].utilizador,
                imputadoContrato: res.data[i].imputadoContrato,
                facturado: res.data[i].facturado,
                tempoEfetivo: tEfetivo,
                duracaoTempoEfetivo: res.data[i].duracaoTempoEfetivo,
              }
              this.intervencoesProcesso.intervencao.push(JSON.parse(JSON.stringify(intervencao)))
            }
          }
        })
        .catch(error => {
          // Lidar com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    getOneProcesso() {
      const processoId = this.$route.params.id // Obtenha o valor do parâmetro "id" da rota atual
      const apiUrl = `/api/v1/processos/${processoId}/complete`

      axios.get(apiUrl)
        .then(res => { this.selectProcesso(res.data) })
        .catch(error => {
          // Lide com erros aqui, se necessário
        })

      this.$forceUpdate()
    },

    getProcesso() {
      axios.get(`/api/v1/processos/tipodoc/${this.processo.tipoDoc}/serie/${this.processo.serie}/numdoc/${this.processo.numProcesso}/complete`)
        .then(res => {
          this.selectProcesso(res.data)
        })
      this.$forceUpdate()
    },

    getContratoCliente() {
      try {
        axios.get(`/api/v1/contratos/entidade/${this.processo.cliente}/complete`)
          .then(res => {
            this.rowsContratos = res.data
            for (let i = 0; i < res.data.length; i += 1) {
              if (res.data[i].contratoAtivoEstado === 1) {
                this.contrato.id = res.data[i].id
                this.processo.contratoID = res.data[i].id
                this.contrato.referencia = res.data[i].referencia
                this.contrato.horasTotais = res.data[i].horasTotais
                this.contrato.horasGastas = res.data[i].horasGastas
                this.contrato.horasDisponiveis = res.data[i].horasDisponiveis
                this.contrato.descricaoEstado = res.data[i].descricaoEstado
              }
            }
          })
          .catch(error => {
            if (error.response.status === 404) {
              this.rowsContratos = undefined
              this.contrato.id = null
              this.processo.contratoID = null
              this.contrato.referencia = null
              this.contrato.horasTotais = 0
              this.contrato.horasGastas = 0
              this.contrato.horasDisponiveis = 0
              this.contrato.descricaoEstado = null
            }
          })
      } catch (error) {
        this.contrato.id = null
        this.processo.contratoID = null
        this.contrato.referencia = null
        this.contrato.horasTotais = 0
        this.contrato.horasGastas = 0
        this.contrato.horasDisponiveis = 0
        this.contrato.descricaoEstado = null
      }
    },

    selectProcesso(row) {
      this.processo.id = row.id
      this.processo.tipoDoc = row.tipoDoc
      this.processo.serie = row.serie
      this.processo.numProcesso = row.numProcesso
      this.processo.processo = row.processo
      this.processo.cliente = row.cliente
      this.processo.tipoProcesso = row.tipoProcesso
      this.processo.motivo = row.motivo
      this.processo.prioridade = row.prioridade
      this.processo.estado = row.estado
      this.processo.tecnico = row.tecnico
      this.processo.descricaoProb = row.descricaoProb
      this.processo.comoReproduzir = row.comoReproduzir
      this.processo.dataHoraAbertura = row.dataHoraAbertura
      this.processo.dataHoraFimPrevista = row.dataHoraFimPrevista
      this.processo.fechado = row.fechado
      this.processo.dataHoraFecho = row.dataHoraFecho
      this.processo.utilizador = row.utilizador
      this.processo.observacoes = row.observacoes
      this.processo.contratoID = row.contratoID
      this.processo.guiaIntervencao = row.guiaIntervencao
      this.processo.faturado = row.faturado
      this.processo.numFatura = row.numFatura
      this.processo.valor = row.valor

      this.contrato.id = row.contratoID
      this.contrato.referencia = row.referenciaContrato
      this.contrato.horasTotais = row.totalHorasContrato
      this.contrato.horasGastas = row.horasGastasContrato
      this.contrato.horasDisponiveis = row.horasDisponiveisContrato
      this.contrato.descricaoEstado = row.descricaoEstadoContrato

      this.cliente.cliente = row.codigoCliente
      this.cliente.id = row.cliente
      this.cliente.nome = row.nomeCliente
      this.cliente.nomeFiscal = row.nomeFiscalCliente
      this.cliente.morada = row.moradaCliente
      this.cliente.codigoPostal = row.codigoPostalCliente
      this.cliente.localidade = row.localidadeCliente
      this.cliente.nif = row.nifCliente
      this.cliente.emailServicos = row.emailServicos
      this.cliente.telefone = row.telefoneCliente
      this.cliente.telemovel = row.telemovelCliente
      this.documentosProcesso.id = row.tipoDoc
      this.documentosProcesso.descricao = row.descricaoDocumentoProcesso
      this.documentosProcesso.codigo = row.codigoDocumentoProcesso
      const serie = {
        serie: row.codigoSerieProcesso,
        id: row.serie,
        descricao: row.descricaoSerieProcesso,
      }
      this.serieSelecionada.serie = serie
      if (this.processo.faturado === 1 || row.codigoEstado === 'REVISTO' || row.codigoEstado === 'TERMINADO' || row.codigoEstado === 'CANCELADO' || row.codigoEstado === 'SUSPENSO') {
        this.editarProcessoLocal = true // Modifica a cópia local
        this.$emit('editar-processo-atualizado', this.editarProcessoLocal) // Emite um evento para o pai
      } else {
        this.editarProcessoLocal = false // Modifica a cópia local
        this.$emit('editar-processo-atualizado', this.editarProcessoLocal) // Emite um evento para o pai
      }
      this.getSeries()

      this.getIntervencoes()
      this.hideModalProcessos()
      this.$forceUpdate()
    },

    selectContratos(row) {
      this.contrato.id = row.id
      this.processo.contratoID = row.id
      this.contrato.referencia = row.referencia
      this.contrato.horasTotais = row.horasTotais
      this.contrato.horasGastas = row.horasGastas
      this.contrato.horasDisponiveis = row.horasDisponiveis
      this.contrato.descricaoEstado = row.descricaoEstado
      this.hideModalContratos()
      this.$forceUpdate()
    },

    selectCliente(row) {
      this.cliente.cliente = row.cliente
      this.cliente.id = row.id
      this.cliente.nome = row.nome
      this.cliente.morada = row.morada
      this.cliente.codigoPostal = row.codigoPostal
      this.cliente.nif = row.nif
      this.cliente.idPais = row.idPais
      this.cliente.idMoeda = row.idMoeda
      this.cliente.idZona = row.idZona
      this.cliente.idVendedor = row.idVendedor
      this.cliente.telefone = row.telefone
      this.cliente.telemovel = row.telemovel
      this.cliente.email = row.email
      this.cliente.nomeFiscal = row.nomeFiscal
      this.cliente.localidade = row.localidade
      this.cliente.emailServicos = row.emailServicos
      this.cliente.ativo = row.ativo
      this.cliente.bloqueado = row.bloqueado
      this.cliente.observacoes = row.observacoes
      this.processo.cliente = row.id
      this.hideModalClientes()
      this.getContratoCliente()
    },

    selectDocumentosProcesso(row) {
      this.serieSelecionada.serie = []
      this.seriesProcesso.series = []
      this.documentosProcesso.id = row.id
      this.processo.tipoDoc = row.id
      this.documentosProcesso.codigo = row.codigo
      this.documentosProcesso.descricao = row.descricao
      this.documentosProcesso.inactivo = row.inactivo
      axios.get(`/api/v1/seriesProcesso/documentosProcesso/${this.documentosProcesso.id}/complete/ativo`)
        .then(res => {
          const resultado = res.data
          this.seriesProcesso.series.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.seriesProcesso.series.push(JSON.parse(JSON.stringify(resultado[i])))
            if (this.seriesProcesso.series[i].porDefeito === 1) {
              this.serieSelecionada.serie = this.seriesProcesso.series[i]
              this.processo.serie = this.seriesProcesso.series[i].id
            }
          }
          this.alterarNumero()
        })
      this.hideModalDocumentosProcesso()
      this.$forceUpdate()
    },

    getSeries() {
      this.seriesProcesso.series = []
      axios.get(`/api/v1/seriesProcesso/documentosProcesso/${this.documentosProcesso.id}/complete/ativo`)
        .then(res => {
          const resultado = res.data
          this.seriesProcesso.series.length = 0
          for (let i = 0; i < resultado.length; i += 1) {
            this.seriesProcesso.series.push(JSON.parse(JSON.stringify(resultado[i])))
          }
        })
      this.hideModalDocumentosProcesso()
      this.$forceUpdate()
    },

    showModalContratos() {
      this.$refs.modalContratos.show()
    },
    hideModalContratos() {
      this.$refs.modalContratos.hide()
    },
    showModalClientes() {
      this.$refs.modalClientes.show()
    },
    hideModalClientes() {
      this.$refs.modalClientes.hide()
    },

    subtraiNumerador(numProcesso) {
      this.processo.numProcesso = numProcesso - 1
    },
    somaNumerador(numProcesso) {
      this.processo.numProcesso = numProcesso + 1
    },

    showModalDocumentosProcesso() {
      this.$refs.modalDocumentosProcesso.show()
    },
    hideModalDocumentosProcesso() {
      this.$refs.modalDocumentosProcesso.hide()
    },
    showModalProcessos() {
      this.$refs.modalProcessos.show()
    },
    hideModalProcessos() {
      this.$refs.modalProcessos.hide()
    },
    alterarNumero() {
      axios.get(`/api/v1/seriesProcesso/${this.processo.serie}/ultimoNumero`)
        .then(res => {
          this.processo.numProcesso = res.data.numerador + 1
        })
      this.$forceUpdate()
    },

  },
}
</script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .textoCentrado {
    text-align: center;
  }
  </style>
